import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
          <input type="hidden" id="anPageName" name="page" value="desktopcopy2" />
          <div className="anima-container-center-horizontal">
            <div className="desktopcopy2 anima-screen">
              <div className="armorbox-h-ader-image-C61RwL">
                <img
                  className="armorbox-h-ader-image-ASt1ON"
                  src="./img/desktop-copy-2-armorbox-header-image-E5F74834-5F0B-4359-90B8-944E28283F64.jpg"
                />
              </div>
              <div className="nav-bar-C61RwL">
                <img
                  className="screen-sho-t-82553-am-zCOIAP"
                  src="./img/desktop-copy-2-screen-shot-2020-10-14-at-82553-am-AA1EEBCC-ADF7-400C-A0BF-5693A5001DDA@2x.png"
                />
              </div>
              <div className="donu2019-t-et-screwed-C61RwL">DON’T GET SCREWED!</div>
              <div className="keep-track-of-the-to-C61RwL">
                KEEP TRACK OF THE TOOLS YOU SHARE.&nbsp;&nbsp;REPLACE THE TOOLS THAT GROW LEGS.
              </div>
              <div className="armorbox-is-built-by-C61RwL">
                Armorbox is built by mechanics for mechanics. We hate wandering the shop for hours hunting down who borrowed
                our tools! Worse, what happens to my tools and livelihood if the shop goes up in smoke, is flooded, or someone
                breaks in… will the company have my back? We found out the hard way and got sick of getting
                screwed.&nbsp;&nbsp;Enough was enough and Armorbox was born.
              </div>
              <div className="button-block-copy-C61RwL">
                <div className="text-K7U6vX anima-valign-text-middle sfprodisplay-normal-white-16px">Coming Soon</div>
              </div>
              <div className="rectangle-copy-9-C61RwL"></div>
              <div className="how-do-we-help-you-C61RwL">HOW DO WE HELP YOU?</div>
              <div className="gain-back-your-time-C61RwL">
                GAIN BACK YOUR TIME, KNOW WHO’S GOT YOUR TOOLS, AND STAY PROTECTED NO MATTER WHAT HAPPENS.
              </div>
              <div className="button-block-copy-3-C61RwL">
                <div className="text-Qw6ow0 anima-valign-text-middle sfprodisplay-normal-white-16px">Coming Soon</div>
              </div>
              <div className="mechanic-C61RwL">MECHANIC</div>
              <div className="group-2-C61RwL">
                <img className="path-zUixHA" src="./img/desktop-copy-2-path-9014737A-E00D-4FAB-9A15-722363CB3469@2x.png" />
                <img className="path-sdxwGy" src="./img/desktop-copy-2-path-4CED7A69-D673-478C-ADDF-5BCD5853B2F6@2x.png" />
              </div>
              <div className="x-armobox-tracking-l-C61RwL">
                <span className="span1-uXwq5E"
                  >- Armobox tracking lets you borrow and share tools between your comrades.&nbsp;&nbsp;
                  <br /><br />
                  - Each of you need an account whether you’re lending or borrowing a tool.<br /><br />- See who has which tool, when
                  they borrowed it, and what it looked like when they took it.
                  <br /><br />- No more wild goose chases; they
                  scan your code, snap a pic and you’re done!
                  <br /><br />- Armorbox protects your tools whether you’re:
                  <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;> An employee in a shop
                  <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;> Independent mechanic at your own shop or home
                  <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;> A mechanic at a mine site or an oil rig*
                  <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;> Mobile mechanic
                  <br /><br />&nbsp;&nbsp;&nbsp;&nbsp;> Even you backyard 
                  </span
                ><span className="span2-uXwq5E">LS swapping into Miata’s “mechanics”</span
                ><span className="span3-uXwq5E"
                  ><br /><br />- Armorbox is NOT intended for construction or trade contractors of any kind.</span
                >
              </div>
              <div className="rectangle-C61RwL"></div>
              <div className="rectangle-copy-C61RwL"></div>
              <div className="send-us-a-message-C61RwL">SEND <br />US A <br />MESSAGE</div>
              <div className="we-want-to-here-from-C61RwL">WE WANT TO HERE FROM YOU!</div>
              <div className="rectangle-VMr6Om"></div>
              <div className="group-4-C61RwL">
                <div className="donu2019-t-be-stupid-KOsP6k">DON’T BE STUPID</div>
                <div className="download-the-app-tr-KOsP6k">
                  DOWNLOAD THE APP.&nbsp;&nbsp;TRACK YOUR TOOLS, PROTECT YOUR TOOLS.&nbsp;&nbsp;BE HAPPY.<br />
                </div>
              </div>
              <div className="group-3-C61RwL">
                <div className="button-block-hvAGCq">
                  <div className="text-H6Bcxh anima-valign-text-middle sfprodisplay-normal-white-16px">Continue</div>
                </div>
                <div className="rectangle-hvAGCq border-class-1"></div>
                <div className="rectangle-copy-2-hvAGCq border-class-1"></div>
                <div className="rectangle-copy-3-hvAGCq border-class-1"></div>
                <div className="rectangle-copy-4-hvAGCq border-class-1"></div>
                <div className="message-hvAGCq poppins-bold-cape-cod-12px">MESSAGE</div>
                <div className="email-hvAGCq poppins-bold-cape-cod-12px">EMAIL</div>
                <div className="last-name-hvAGCq poppins-bold-cape-cod-12px">LAST NAME</div>
                <div className="first-name-hvAGCq poppins-bold-cape-cod-12px">FIRST NAME</div>
              </div>
              <img
                className="e96332463a-61original-C61RwL"
                src="./img/desktop-copy-2-e96332463a639b0cb3db9957466b2961original-26C94A1D-8D5A-4F53-9CBF-A95823474A73.png"
              />
              <img className="triangle-C61RwL" src="./img/desktop-copy-2-triangle-AB6081FC-5F02-4AA0-8E1D-19B282A8A45E@2x.png" />
              <div className="coming-soon-C61RwL">COMING SOON</div>
              <img className="triangle-VMr6Om" src="./img/desktop-copy-2-triangle-AB6081FC-5F02-4AA0-8E1D-19B282A8A45E@2x.png" />
              <div className="coming-soon-VMr6Om">COMING SOON</div>
            </div>
          </div>
      </div>
    );
  }
}

export default App;